//项目已经全局引入element的话可以不单独引入
import { Message } from "element-ui";
import router from "@/router";
import VueUeditorWrap from "vue-ueditor-wrap";
import { randomString, returnLetterByIndex, sleep, startLoading, endLoading } from "@/utils/function";
import { editorUploadUrl } from "@/utils/const";
import API from "@/api/teacher/report/reportTemplateUpdate";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "questionBankUpdate",
    // 页面组件
    components: {
        VueUeditorWrap
    },
    inject: ['reload'],
    // 数据
    data() {
        return {
            // ueditor 编辑器配置项
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                    [
                        "inserttable",//表格
                        "source", //源代码
                        "bold", //加粗
                        "italic", //斜体
                        "underline", //下划线
                        "strikethrough", //删除线
                        "fontborder", //字符边框
                        "blockquote", //引用
                        "selectall", //全选
                        "horizontal", //分隔线
                        "removeformat", //清除格式
                        "unlink", //取消链接
                        "deletecaption", //删除表格标题
                        "inserttitle", //插入标题
                        "cleardoc", //清空文档
                        "insertcode", //代码语言
                        "fontfamily", //字体
                        "fontsize", //字号
                        "insertimage", //多图上传
                        "link", //超链接
                        "emotion", //表情
                        "spechars", //特殊字符
                        "searchreplace", //查询替换
                        "insertvideo", //视频
                        "justifyleft", //居左对齐
                        "justifyright", //居右对齐
                        "justifycenter", //居中对齐
                        "forecolor", //字体颜色
                        "insertorderedlist", //有序列表
                        "insertunorderedlist", //无序列表
                        "imageleft", //左浮动
                        "imageright", //右浮动
                        "attachment", //附件
                        "imagecenter", //居中
                        "lineheight", //行间距
                    ],
                ],
            },
            back: 1,
            // 选择的关卡
            guanka: '',
            // 选择的虚拟实验ID
            expID: '',
            // 关卡列表
            guankaList: '',
            // 虚拟实验列表数据
            expList: [],
            // 报告模板名称
            reportTitle: "",
            // 试卷主键
            bank_id: 0,
            // 试题数组
            questionList: [],
            // 类型下拉列表
            options: [
                // {
                //   value: "image",
                //   label: "图片",
                // },
                {
                    value: "text",
                    label: "富文本（图片+文字）",
                },
                {
                    value: "table",
                    label: "表格",
                },
                {
                    value: "xuni",
                    label: "虚拟",
                },
            ],
            knowledge: [{ label: "按下回车添加" }],
            // 弹窗标识
            dialogVisible: false,
            // 表格数组
            tableArray: [],
            // 当前操作的数组索引
            arrayIndex: 0,
            // 是否选择了关卡,不选择关卡不能提交数据
            isSelectguanka: false,
            // 拷贝项目列表
            projectList2: [],
            // 试卷总分
            totalScore:0,
        };
    },
    // 生命周期---创建
    created() {
        const self = this;
        // 接受参数
        self.bank_id = Number(this.$router.currentRoute.query.bank_id);
        self.reportTitle = self.$route.query.name
        self.back = self.$route.query.back
        // 获取数据
        self.getData();
    },
    // 方法
    methods: {
        /**
         * @name: 计算试卷总分
         * @author: camellia
         * @date: 2023-09-04
         */
        calcScore()
        {
            const self = this;
            self.totalScore = 0;
            for (const item of self.questionList) 
            {
                if(parseFloat(item.questionScore))
                {
                    self.totalScore += parseFloat(item.questionScore);
                }
            }
        },
        /**
         * @name: 返回上一级
         * @author: camellia
         * @date: 2021-01-15 14:00:30
         */
        goBack() {
            // this.$router.back()
            let self = this;
            self.$router.push({
                path: "/teacher/report/reportTemplate",
                query: {
                    // article_id: self.article_id,
                    // name:self.categoryName,
                    hideMenu: 0
                }
            }).then((res) => {
                self.reload()
                // console.log(res);
            })
        },
        /**
         * @name: 选择关卡
         * @author: lxy
         * @date: 20211023
         */
        HandleSelectguanka() {
            var self = this;
            self.isSelectguanka = true;
        },
        /**
         * @name: 根据关键词搜索虚拟实验
         * @author: lxy
         * @date: 20211023
         */
        SearchExp(value) {
            var self = this;
            // console.log(value);
            let data = {
                searchName: value
            }
            API.getExpList(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.expList = result.data;
                    }
                    // 关闭loading
                    // endLoading(startLoading());
                })
                .catch(() => {
                    // 关闭loading
                    // endLoading(startLoading());
                })
            // self.axios
            //     .post("/index.php/report/getExpList",data)
            //     .then((result)=>{
            //         self.expList=result.data.data;
            //     })
        },
        /**
         * @name: 选择虚拟实验
         * @author: lxy
         * @date: 20211023
         */
        HandleSelectExp(id) {
            var self = this;
            const exp = self.expList.filter((item) => { return item.id == id })
            // console.log(exp);
            self.guankaList = exp[0].CID;
        },
        /**
         * @name: 修改试题排序
         * @author: camellia
         * @date: 2021-02-24 
         */
        sortNoUpdate() {
            const self = this;
            self.questionList.sort(self.sortBy("index"));
        },
        /**
         * @name: 数组根据属性排序
         * @author: camellia
         * @date: 2021-02-24 
         * @param:	property	string	数组属性
         */
        sortBy(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            };
        },
        /**
         * @name: 打开设置表格弹窗
         * @author: camellia
         * @date: 2021-02-23
         * @param:	line	number	表格行数
         * @param:	column	number	表格列数
         */
        openDialog(line, column, index) {
            const self = this;
            self.arrayIndex = index;
            if ((line == self.questionList[self.arrayIndex].saveline && column == self.questionList[self.arrayIndex].savecolumn) && self.questionList[self.arrayIndex].tableArray.length > 0) {
                self.tableArray = self.questionList[self.arrayIndex].tableArray;
            }
            else {
                if (line == 0 || column == 0) {
                    // Message.warning("表格行列不能为0！");return;
                    return this.$Tips({
                        // 消息提示内容
                        message: "表格行列不能为0",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                }
                self.tableArray = [];
                for (var i = 0; i < line; i++) {
                    var array = [];
                    for (var j = 0; j < column; j++) {
                        array.push('');
                    }
                    self.tableArray.push(array);
                }
            }
            self.dialogVisible = true;
        },
        /**
         * @name: dialog点击确定
         * @author: camellia
         * @date: 2021-02-23 
         */
        dialogCommit() {
            const self = this;
            self.questionList[self.arrayIndex].tableArray = self.tableArray;
            self.questionList[self.arrayIndex].saveline = self.questionList[self.arrayIndex].line;
            self.questionList[self.arrayIndex].savecolumn = self.questionList[self.arrayIndex].column
            self.dialogVisible = false;
        },
        /**
         * @name: 添加试题
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-01-29
         * @param:	sign	string	试题类型
         */
        addquestion(sign) {
            const self = this;
            let obj = {
                // ===========================  ueditor  使用代码 start   ================
                defaultMsg: "", //默认显示的文字
                ue1: "", // 不同编辑器必须不同的id
                ueState: false, //ue状态
                // ===========================  ueditor  使用代码 end      ================
                // 序号
                index: 1,
                // 试题类型
                questionType: "text",
                // 试题分数
                questionScore: "",
                // 是否必答
                must_answer: false,
                // 标记
                sign: "",
                // 算法
                reckon: "",
                // 行数
                line: "",
                saveline: -1,
                // 列数
                column: "",
                savecolumn: -1,
                // 表格数据
                tableArray: [],
                // 虚拟实验关卡
                guanka: '',
                // 虚拟实验ID
                exp_id: ''
            };
            obj.questionType = sign;
            obj.ue1 = randomString(10);
            obj.index = self.questionList.length + 1;
            // 将试题对象添加至试题列表中
            self.questionList.push(obj);
            // Message.success("添加成功！");
            // 滚动条移动到最下
            sleep(500).then(() => {
                // 这里写sleep之后需要去做的事情
                var div = document.getElementById("scrolldIV");
                div.scrollTop = div.scrollHeight;
            });
            this.$Tips({
                // 消息提示内容
                message: '添加成功',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
            })
        },
        /**
         * @name: 删除试题
         * @author: camellia
         * @date: 2021-01-28
         * @param:	index	number	下标
         */
        deleteQuestion(index) {
            const self = this;
            // 当只剩下一个选项的时候不能删除
            if (self.questionList.length <= 1) {
                // Message.warning("只有一个试题不能删除！");
                this.$Tips({
                    // 消息提示内容
                    message: "只有一个试题不能删除",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
                return;
            }
            self.questionList.splice(index, 1); //在下标index处开始删除,删除一位
            // Message.success("删除成功！");
            this.$Tips({
                // 消息提示内容
                message: '删除成功',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                displayTime: 1500
            })
        },
        /**
         * @name: 返回模板列表页
         * @author: camellia
         * @date: 2021-01-28
         */
        // goBack() {
        //     // this.$router.push("/manager/teacher/report/reportTemplate");
        //     let self = this;
        //     self.$router.back();
        // },
        /**
         * @name: 获取editor示例
         * @author: camellia
         * @date: 2021-01-18 10:13:50
         */
        ready(editorInstance) {
            var self = this;
            editorInstance.focus();
            var str = "report_exam_" + self.bank_id;
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function (editor) {
                return {
                    articleid: str //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        /**
         * @name: 保存题库
         * @author: camellia
         * @date: 2021-01-28
         */
        saveQuestionBank() {
            var self = this;
            // 验证虚拟实验ID是否包含中文
            for (var i = 0; i < self.questionList.length; i++) {
                if (self.questionList[i].questionScore == "") {
                    // Message.warning("请填写分数！");
                    this.$Tips({
                        // 消息提示内容
                        message: "请填写分数",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                /*if (self.questionList[i].sign.length == 0) {
                    Message.warning("请填写标记！");
                    return;
                }//*/
                // if ( (self.questionList[i].questionType == "table" &&  self.questionList[i].reckon == "") || (self.questionList[i].questionType == "xuni" &&  self.questionList[i].reckon == "") ) 
                // DELETE BY LANXINYU 2021-10-28====================================================================
                // 虚拟可以不添加算法ID
                // if ( (self.questionList[i].questionType == "xuni" &&  self.questionList[i].reckon == "") ) 
                // {
                //     Message.warning("请填写算法id！");
                //     return;
                // } 
                // =================================================================================================
                if (self.questionList[i].questionType == "table" && self.questionList[i].tableArray.length == 0) {
                    // Message.warning("请设置表格数据！");
                    this.$Tips({
                        // 消息提示内容
                        message: "请设置表格数据",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                // ADD BY LANXINYU 2021-11-23===========================================================================================
                // 不选择虚拟实验和关卡不能保存
                if (self.questionList[i].questionType == "xuni" && self.questionList[i].exp_id == '') {
                    // Message.warning("请选择虚拟实验！");
                    this.$Tips({
                        // 消息提示内容
                        message: "请选择虚拟实验",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
                if (self.questionList[i].questionType == "xuni" && self.questionList[i].guanka == '') {
                    // Message.warning("请选择关卡！");
                    this.$Tips({
                        // 消息提示内容
                        message: "请选择关卡",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        button: [
                            {
                                type: 1,
                                text: '知道了'
                            }
                        ]
                    })
                    return;
                }
            }
            self.questionList.forEach((item, index) => {
                let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
                if (reg.test(item.exp_id)) {
                    item.exp_id = self.projectList2[index].exp_id;
                }
            })
            let data = {
                params: {
                    questionList: self.questionList,
                    bank_id: self.bank_id,
                }
            };
            // 当选择了小游戏关卡时
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.updateReportTemplateExam(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.getData();
                        // Message.success(result.msg);
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(() => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
        },
        /**
         * @name: 获取初始数据
         * @author: camellia
         * @email: guanchao_gc@qq.com
         * @date: 2021-02-01
         */
        getData() {
            const self = this;
            let data = {
                bank_id: self.bank_id
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            self.totalScore = 0;
            // 请求数据
            API.getReportTemplateExam(data)
                .then((result) => {
                    if (result.code > 0) 
                    {
                        if (result.data == "") 
                        {
                            // 将试题对象添加至试题列表中
                            self.addquestion("text");
                        }
                        else 
                        {
                            console.log(result.data)
                            self.projectList2 = JSON.parse(JSON.stringify(result.data));
                            for (const item of result.data) 
                            {
                                if (item.questionType == 'xuni') {
                                    item.exp_id = item.expName;
                                }
                                if (item.must_answer == 'true') {
                                    item.must_answer = true;
                                }
                                else 
                                {
                                    item.must_answer = false;
                                }
                                if(parseFloat(item.questionScore))
                                {
                                    self.totalScore += parseFloat(item.questionScore);
                                }
                                if(item.defaultMsg)
                                {
                                    // item.defaultMsg = item.defaultMsg.replace(/<[^>]+>/g, '');
                                }
                            }
                            console.log(result.data)
                            self.questionList = result.data;
                        }
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(() => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
        }
    }
};
