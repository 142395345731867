<template>
    <!-- 编辑题库页 -->
    <div class="reportTemplateUpdate">
        <!-- 保存/立即开始按钮 -->
        <!-- <div class="save-button"> -->
            <!-- <div class="reportTitle">{{reportTitle}}</div> -->
            <!-- <div class="right"> -->
                <!-- <el-button type="primary" @click="saveQuestionBank()"
                    >保存</el-button> -->
                <!-- <el-button type="success">立即开始</el-button> -->
                <!-- <el-button @click="goBack()">返回</el-button> -->
            <!-- </div> -->
        <!-- </div> -->
        <div class="edit-content">
            <div class="edit-view" id="scrolldIV">
                <!-- 试题内容 -->
                <div
                    class="question-content"
                    v-for="(item, index) in questionList"
                    :key="index"
                    :id="index"
                >
                    <!-- 试题header -->
                    <div class="question-header-div">
                        <!-- 序号 -->
                        <span class="margin-left1">序号：
                            <el-input
                                v-model="item.index"
                                @blur="sortNoUpdate(item.index)"
                                style="width:60px"
                            ></el-input>
                        </span>
                        <!-- 类型选项 -->
                        <span class="margin-left4">
                            <el-select v-model="item.questionType" placeholder="请选择">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </span>
                        <span class="margin-left4">
                            分数：
                        </span>
                        <span>
                            <el-input
                                v-model="item.questionScore"
                                placeholder="请输入分数"
                                type="number"
                                @input="calcScore"
                            ></el-input>
                        </span>

                        <span class="right cursor delete-icon"
                            ><i
                                class="el-icon-delete"
                                @click="deleteQuestion(index)"
                            ></i
                        ></span>
                    </div>
                    <!-- 富文本编辑器编辑题干 -->
                    <div>
                        <vue-ueditor-wrap
                            v-model="item.defaultMsg"
                            :config="myConfig"
                            @ready="ready"
                        ></vue-ueditor-wrap>
                    </div>
                    <!-- 选项 -->
                    <div>
                        <div class="Option">
                            <div>
                                <span >
                                    标记：
                                </span>
                                <span>
                                    <el-form ref="form">
                                        <el-select v-model="item.sign" clearable multiple filterable allow-create default-first-option placeholder="输入标签，按下回车添加" class="write-input">
                                            <el-option v-for="it in knowledge" :key="it.value" :label="it.label" :value="it.label" disabled ></el-option>
                                        </el-select>
                                    </el-form>
                                </span>
                            </div>
                            <div v-if="item.questionType == 'table' || item.questionType == 'xuni'" class="margin-left1" >
                                <span >
                                    算法id：
                                </span>
                                <span >
                                    <el-input
                                    class="suanfaID"
                                        v-model="item.reckon"
                                        placeholder="选填"
                                    ></el-input>
                                </span>
                            </div>
                            <div v-if="item.questionType == 'xuni'" class="ExpAndLevel">
                                <div class="ExpAndLevel">
                                    <div class="EXP">
                                        虚拟实验：
                                    </div>

                                    <span >
                                        <el-select v-model="item.exp_id" @change="HandleSelectExp(item.exp_id)"  filterable placeholder="请输入虚拟实验名称"  :filter-method="SearchExp" >
                                            <el-option
                                            v-for="item in expList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </span>
                                </div>
                                <div  class="margin-left4 inlineBlock ExpAndLevel">
                                        <div class="level">
                                            关卡：
                                        </div>
                                    <span >
                                        <el-select v-model="item.guanka" @change="HandleSelectguanka"   placeholder="请选择" class="guankaID" >
                                            <el-option
                                                v-for="item in guankaList"
                                                :key="item"
                                                :label="item"
                                                :value="item">
                                            </el-option>
                                        </el-select>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                        <!-- 表格 -->
                        <div v-if="item.questionType == 'table'">
                        <span>表格：</span>
                            <el-input
                                v-model="item.line"
                                placeholder="请输入行数"
                                class="table-input"
                                type="number"
                            ></el-input>
                            <el-input
                                v-model="item.column"
                                placeholder="请输入列数"
                                class="table-input"
                                type="number"
                            ></el-input>
                            <el-button class="themeButton" @click="openDialog(item.line,item.column,index)" >设置表格</el-button>
                        </div>
                    </div>
                    <!-- 必答题 + 多选最多几个 -->
                    <div>
                        <!-- 必答题 -->
                        <span class="must-span">
                            必答：<el-switch active-color="#0D5570" v-model="item.must_answer" class="must-checkbox"></el-switch>
                        </span>
                        <!-- 多选最多几个 -->
                        <!-- <span v-if="item.questionType == 'checkbox'"></span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="floatRight" style="padding-bottom: 3vw;">
            <div class="add-button-div">
                <!-- 添加题按钮 -->
                <div class="add-question-div" >
                    <div class="addtitleText">添加题目</div>
                    <div @click="addquestion('text')" class="add-button">
                        <div class="icon"><img src="@/assets/img/1.png" class="img" ></div>
                        <div class="title">富文本</div>
                    </div>
                    <div @click="addquestion('table')" class="add-button mar-left">
                        <div class="icon"><img src="@/assets/img/2.png" class="img" ></div>
                        <div class="title">表 格</div>
                    </div>
                    <div @click="addquestion('xuni')" class="add-button mar-left">
                        <div class="icon" style="padding: 0.6vw;padding-top: 0.9vw;"><img src="@/assets/img/3.png" class="img" style="width: 2.3vw;"></div>
                        <div class="title">虚 拟</div>
                    </div>
                    <!-- <el-button plain @click="addquestion('image')">图片</el-button> -->
                    <!-- <el-button plain class="typeButton" @click="addquestion('text')">富文本</el-button>
                    <el-button plain class="typeButton" @click="addquestion('table')">表格</el-button>
                    <el-button plain class="typeButton" @click="addquestion('xuni')">虚拟</el-button> -->
                </div>
            </div>
            <div class="questionIndex">
                <div class="addtitleText" style="margin-bottom: 0vw;border-bottom: 1px solid #8b8787;">
                    <span>题目</span>
                    <span style="float: right;">总分:{{totalScore}}</span>
                </div>
                <div class="scrollquestionIndex">
                    <div class="indexButton"  v-for="(item,index) in questionList" :key="index">
                        <div>
                            <a :href="'#'+index">
                                <span class="span-index">{{index+1}}.</span>
                                <!-- <span class="span-content">{{item.defaultMsg}}</span> -->
                                <span class="span-content">{{(item.defaultMsg == "" || item.defaultMsg == null) ? '' : item.defaultMsg.replace(/<[^>]+>/g, '')}}</span>
                            </a>
                        </div>
                        
                    </div>  
                </div>
            </div>
        </div>
        
        <div class="backFix" v-show="!back">
            <el-button class="themecancelButton" @click="goBack()">返回</el-button>
            <!-- <el-button type="primary" @click="execUpdateArticle()"
                >提&nbsp;&nbsp;交</el-button
            > -->
        </div>
        <div class="saveFix" style="margin-bottom: 0px;">
            <div class="saveBox">
                <div class="floatRight">
                    <el-button class="themeButton" @click="saveQuestionBank()">保存</el-button>
                </div>
            </div>
        </div>
        <!-- 表格设置弹窗 -->
        <el-dialog
            title="设置表格内容"
            :visible.sync="dialogVisible"
            width="70%">
            <div class="warning-div">@代表计算属性，#代表正常输入值，留空代表不可编辑</div>
            <!-- 内容 -->
            <table style="width:100%">
                <tr  v-for="(item,index) in tableArray" :key="index" style="width:100%">
                    <td v-for="(value,it) in item" :key="it" >
                        <el-input v-model="tableArray[index][it]" placeholder="请输入内容" ></el-input>
                    </td>
                </tr>
            </table>
            <!-- 按钮 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogCommit()">保 存</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<style lang="scss" scoped>
    @import "@/assets/css/teacher/report/reportTemplateUpdate.scss";
    
</style>
<style lang="scss"> 
.reportTemplateUpdate .el-main{
    padding: 0 !important;
}
.reportTemplateUpdate .el-input__inner{
    font:normal normal normal 0.8854vw Source Han Sans CN;
    height: 2.0833vw;
}
</style>
<script>
    import reportTemplateUpdate from "@/assets/js/teacher/report/reportTemplateUpdate.js";
    export default {
        ...reportTemplateUpdate
    };
</script>
