import { request } from "@/request/http";

export default {
  getExpList: (data) => {
    return request("POST", `/index.php/report/getExpList`, data);
  },

  updateReportTemplateExam: (data) => {
    return request("POST", `/index.php/report/updateReportTemplateExam`, data);
  },

  getReportTemplateExam: (data) => {
    return request("GET", `/index.php/report/getReportTemplateExam`, data);
  },
};